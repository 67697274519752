import React from 'react';
import { Link } from '../components';

function Design() {
  return (
    <article className="text-gray-800 body-font">
      <div className="container px-5 py-5 mx-auto flex flex-col items-center">
        <div className="prose prose-lg">
          <h4>Thorlorn Design</h4>
          <p>
          </p>
        </div>
      </div>
    </article>
  );
}

export default Design;
