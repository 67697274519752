import React from 'react';

function Overview() {
  return (
    <article className="text-gray-800 body-font">
    <div className="container px-5 py-5 mx-auto flex flex-col items-center">
      <div className="prose prose-lg">
        <h4>Thorlorn Design Overview</h4>
      </div>
    </div>
  </article>
);
}

export default Overview;
