import React from 'react';
import { DiscordLink, TwitterLink } from '../components';
import { Link } from 'react-router-dom';
import { DiscordUrl, TwitterUrl } from '../constants';

function Home() {
  return (
    <>
      <section className="text-gray-800 body-font">
        <div className="container px-5 py-5 mx-auto flex flex-wrap">
          <h2 className="sm:text-3xl text-2xl text-gray-900 font-medium title-font mb-2 md:w-2/5">
            Thorlorn Design
          </h2>
          <div className="md:w-3/5 md:pl-6">
            <p className="prose prose-lg">
              asdf adsf asdf adsf 
            </p>
            <p className="prose prose-lg mt-2">
              asdf asdf asdf asdf asd f
            </p>
            <div className="flex md:mt-4 mt-6">
              <Link className="text-indigo-500 inline-flex items-center" to="/overview">
                <span>Overview</span>
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-800 body-font">
        <div className="container px-5 py-5 mx-auto flex flex-wrap">
          <h2 className="sm:text-3xl text-2xl text-gray-900 font-medium title-font mb-2 md:w-2/5">Design</h2>
          <div className="md:w-3/5 md:pl-6">
            <p className="prose prose-lg">
              asdf adsf asdf adsf 
            </p>
            <p className="prose prose-lg mt-2">
              asdf asdf asdf asdf asd f
            </p>
            <div className="flex md:mt-4 mt-6">
              <Link className="text-indigo-500 inline-flex items-center" to="/design">
                <span>Design</span>
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
